/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress } from "@mui/material";
import { red } from "@mui/material/colors";
import { Participant } from "@tatami-web/domain";
import {
  AvailableActions,
  fetchIceServers,
  IceServers,
  iceServersUnused,
  selectIceServers,
  useAppDispatch,
  useAppSelector,
} from "@tatami-web/shared";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  useRtcPeerConnection,
  WebRTCDirection,
} from "../../../../../hooks/useRtcPeerConnection";
import FighterActions from "../actions/FighterActions";
import FighterToolbar from "../actions/FighterToolbar";
import OpponentActions from "../actions/OpponentActions";
import { useRoomContext } from "../RoomContext";
import StateMessage from "./StateMessage";

export interface FighterVideoProps {
  videoEmitterSid: string;
  participant: Participant;
  availableActions?: AvailableActions;
}

function FighterVideo(props: FighterVideoProps) {
  const { t } = useTranslation();
  const roomContext = useRoomContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const direction =
    roomContext.session.sid !== props.videoEmitterSid
      ? WebRTCDirection.RecieveOnly
      : WebRTCDirection.SendReceive;

  const wsSession = roomContext.wsSession;
  const dispatch = useAppDispatch();

  const iceServers: IceServers | undefined = useAppSelector(
    selectIceServers(props.videoEmitterSid)
  );

  const peerConnectionState = useRtcPeerConnection(videoRef, wsSession, {
    iceServers,
    videoEmitterSid: props.videoEmitterSid,
    direction: direction,
    deviceSelection: roomContext.deviceSelection,
  });

  useEffect(() => {
    dispatch(fetchIceServers(props.videoEmitterSid));
    return () => {
      dispatch(iceServersUnused(props.videoEmitterSid));
    };
  }, [props.videoEmitterSid, dispatch]);

  //All possible states ["closed" | "connected" | "connecting" | "disconnected" | "failed" | "new"]
  const awaitingStates = ["connecting", "new"];
  const failureStates = ["disconnected", "failed"];

  let message = null;

  if (
    !peerConnectionState ||
    awaitingStates.some((s) => s === peerConnectionState)
  ) {
    message = (
      <StateMessage
        message={t(
          `fight.room.lobby.video.connection.state.${peerConnectionState}`
        )}
        icon={<CircularProgress />}
      />
    );
  } else if (failureStates.some((s) => s === peerConnectionState)) {
    message = (
      <StateMessage
        message={t(
          `fight.room.lobby.video.connection.state.${peerConnectionState}`
        )}
        icon={<ErrorIcon fontSize="large" sx={{ color: red[500] }} />}
      />
    );
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {message}
      <video
        css={css`
          width: 100%;
          flex: 1;
          position: ${message ? "absolute" : "relative"};
          visibility: ${message ? "hidden" : "visible"};
        `}
        disablePictureInPicture
        ref={videoRef}
        playsInline
      ></video>
      <FighterToolbar sx={{ alignSelf: "flex-end" }}>
        {props.availableActions ? (
          <FighterActions
            participant={props.participant}
            availableActions={props.availableActions}
          />
        ) : (
          <OpponentActions participant={props.participant} />
        )}
      </FighterToolbar>
    </Box>
  );
}

export default FighterVideo;
