import { FormControl, Grid, InputLabel, Slider } from "@mui/material";
import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { CreateFightFormValues } from "@tatami-web/shared";
import { formatMinutes } from "@tatami-web/shared";

function ChessFightRulesForm(props: ChessFightRulesFormProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl required fullWidth>
          <InputLabel shrink htmlFor="chessFightRules.maxSpeechDuration">
            {t("fight.create.form.maxSpeechDuration.label")}
          </InputLabel>
          <Field
            component={Slider}
            name="chessFightRules.maxSpeechDuration"
            id="chessFightRules.maxSpeechDuration"
            onChange={(e: React.SyntheticEvent, v: string) => {
              props.setFieldValue("chessFightRules.maxSpeechDuration", v);
            }}
            valueLabelFormat={formatMinutes}
            valueLabelDisplay="auto"
            defaultValue={900}
            marks={[
              {
                value: 300,
                label: formatMinutes(300),
              },
              {
                value: 900,
                label: formatMinutes(900),
              },
              {
                value: 1800,
                label: formatMinutes(1800),
              },
            ]}
            min={300}
            max={1800}
            step={60}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl required fullWidth>
          <InputLabel shrink htmlFor="chessFightRules.interruptions">
            {t("fight.create.form.interruptions.label")}
          </InputLabel>
          <Field
            component={Slider}
            name="chessFightRules.interruptions"
            id="chessFightRules.interruptions"
            onChange={(e: React.SyntheticEvent, v: string) => {
              props.setFieldValue("chessFightRules.interruptions", v);
            }}
            valueLabelDisplay="auto"
            marks={[
              {
                value: 0,
                label: "None",
              },
              {
                value: 5,
                label: "5",
              },
            ]}
            defaultValue={0}
            min={0}
            max={5}
            step={1}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <FormControl required fullWidth>
          <InputLabel shrink htmlFor="chessFightRules.interruptionTime">
            {t("fight.create.form.interruptionTime.label")}
          </InputLabel>
          <Field
            component={Slider}
            name="chessFightRules.interruptionTime"
            id="chessFightRules.interruptionTime"
            onChange={(e: React.SyntheticEvent, v: string) => {
              props.setFieldValue("chessFightRules.interruptionTime", v);
            }}
            valueLabelDisplay="auto"
            valueLabelFormat={formatMinutes}
            marks={[
              {
                value: 60,
                label: formatMinutes(60),
              },
              {
                value: 180,
                label: formatMinutes(180),
              },
            ]}
            defaultValue={60}
            min={60}
            max={180}
            step={30}
            disabled={props.values.chessFightRules?.interruptions === 0}
          />
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}

interface ChessFightRulesFormProps {
  values: CreateFightFormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export default ChessFightRulesForm;
