import { Box, Container, Grid, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Route, Routes } from "react-router-dom";
import theme from "../../theme";
import Error404 from "./Error404";
import ErrorConnection from "./ErrorConnection";
import NoMediaPermission from "./NoMediaPermission";

function Errors() {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        backgroundColor: grey[900],
        p: 2,
      }}
    >
      <Container maxWidth="lg">
        <Paper
          sx={{
            padding: theme.spacing(2),
            minHeight: "30vh",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Routes>
              <Route
                path={`noMediaPermission`}
                element={<NoMediaPermission />}
              />
              <Route path={`ConnectionError`} element={<ErrorConnection />} />
              <Route path={`404`} element={<Error404 />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}

export default Errors;
