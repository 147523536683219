/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { AppBar, Box, CssBaseline, Grid, styled, Toolbar } from "@mui/material";
import { AxiosError } from "axios";
import { browserSessionPersistence, getAuth } from "firebase/auth";
import { useCallback, useEffect } from "react";
import { theme, useAppDispatch, useAppSelector } from "@tatami-web/shared";
import { Route, Routes, Link, Navigate } from "react-router-dom";
import { API } from "@tatami-web/shared";
import LoginWidget from "./components/appbar/login/LoginWidget";
import Logo from "./logo_text_black_192_right.png";
import Error404 from "./pages/errors/Error404";
import Errors from "./pages/errors/Errors";
import Loading from "./pages/fights/fight/room/Loading";
import Fights from "./pages/fights/Fights";
import LoginPage from "./pages/login/Login";
import ThirdPartyInjector from "./ThirdPartyInjector";
import {
  clearLoginState,
  fetchSessionState,
  isUserAuthenticatedSelector,
  LoginStatus,
  selectSessionState,
  setUser,
  UserResponse,
} from "@tatami-web/shared";
import Home from "./pages/home/Home";

export const AppBarOffset = styled("div")(({ theme }) => theme.mixins.toolbar);

function App() {
  const dispatch = useAppDispatch();
  const auth = getAuth();

  const sessionState = useAppSelector(selectSessionState);
  const authenticated = useAppSelector(isUserAuthenticatedSelector);

  const refresh = useCallback(
    async (
      displayName: string,
      email: string,
      uid: string,
      profilePictureUrl: string
    ) => {
      const userData: UserResponse = {
        displayName,
        email,
        uid,
        profilePictureUrl,
      };
      return dispatch(setUser(userData));
    },
    [dispatch]
  );

  useEffect(() => {
    const f = async () => {
      auth.onAuthStateChanged(async (user) => {
        if (user && !authenticated) {
          const idToken = await user.getIdToken(true);
          try {
            await API.post(`/session/validate`, idToken, {
              headers: { "Content-Type": "text/plain" },
            });
          } catch (e: unknown) {
            if (e instanceof AxiosError) {
              if (e.response?.status === 401 || e.response?.status === 400) {
                dispatch(clearLoginState());
                return null;
              } else {
                console.error("There was an error validating the session", e);
                return null;
              }
            } else {
              console.error("There was an error validating the session", e);
              return;
            }
          }

          return await refresh(
            //@ts-ignore
            user.displayName,
            user.email,
            user.uid,
            user.photoURL
          );
        }
        if (!user && !authenticated) {
          try {
            await API.post(`/session/invalidate`);
          } catch (e) {
            console.error("Error invalidating session", e);
          }
          dispatch(clearLoginState());
        }
      });
      await getAuth().setPersistence(browserSessionPersistence);
    };
    f();
  });

  useEffect(() => {
    if (!sessionState.fetchedOnce && !sessionState.fetching) {
      dispatch(fetchSessionState());
    }
  }, [dispatch, sessionState.fetchedOnce, sessionState.fetching]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100svh",
        [theme.breakpoints.down("lg")]: {
          height: "100svh",
        },
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Link
            to="/home"
            css={css`
              font-size: 0;
            `}
          >
            <img
              src={Logo}
              alt="Tatami Logo"
              css={css`
                max-width: 100px;
              `}
            />
          </Link>
          {sessionState.status !== LoginStatus.Unknown ? (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs />
              <LoginWidget />
            </Grid>
          ) : null}
        </Toolbar>
      </AppBar>
      <AppBarOffset />
      {sessionState.status !== LoginStatus.Unknown ? (
        <Box sx={{ display: "flex", flex: "1 1 auto", overflow: "auto" }}>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/fights/*" element={<Fights />} />
            <Route path="/error/*" element={<Errors />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Box>
      ) : (
        <Loading />
      )}
      <ThirdPartyInjector />
    </Box>
  );
}

export default App;
