import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { store } from "@tatami-web/shared";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { register } from "./firebaseApp";
import "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import CookieConsent from "react-cookie-consent";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

register();

const handleCookieConsentChanged = () => {
  window.location.reload();
};

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Router>
            <CssBaseline />
            <App />
            <CookieConsent
              style={{
                zIndex: theme.zIndex.drawer + 1,
              }}
              enableDeclineButton
              // debug={process.env.NODE_ENV === "development"}
              onDecline={handleCookieConsentChanged}
              onAccept={handleCookieConsentChanged}
            >
              This website uses cookies to enhance the user experience.
            </CookieConsent>
          </Router>
        </Provider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
