import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { EmailAuthProvider, getAuth, GoogleAuthProvider } from "firebase/auth";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import StyledFirebaseAuth from "./StyledFirebaseAuth";

const PREFIX = "Login";

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login(props: LoginProps) {
  const { t } = useTranslation();

  const auth = useCallback(getAuth, []);

  const uiConfig = {
    siteName: "tatami",
    tosUrl: "https://tatami.app/#terms",
    privacyPolicyUrl: "https://tatami.app/#privacy",
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    callbacks: {
      signInSuccessWithAuthResult: props.onLoggedIn,
    },
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    ],
    signInSuccessUrl: props.nextUrl,
  } as firebaseui.auth.Config;

  return (
    <StyledContainer maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("user.login.header.title")}
        </Typography>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
      </div>
    </StyledContainer>
  );
}

export interface LoginProps {
  onLoggedIn?: () => void;
  onSwitch: () => void;
  nextUrl?: string;
}
