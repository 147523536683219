import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

import { LoadingButton } from "@mui/lab";
import React from "react";
import { useTranslation } from "react-i18next";

enum SpeakerButtonState {
  Ready = "Ready",
  Loading = "Loading",
  Playing = "Playing",
}

export default function SpeakerSelector(props: SpeakerSelectorProps) {
  const { t } = useTranslation();

  const [testSpeakerButtonState, setTestSpeakerButtonState] =
    useState<SpeakerButtonState>(SpeakerButtonState.Ready);

  function handleTestSoundClick() {
    var snd = new Audio(process.env.PUBLIC_URL + "/sounds/applause.mp3");
    setTestSpeakerButtonState(SpeakerButtonState.Loading);
    snd.addEventListener("canplaythrough", (event) => {
      //@ts-ignore
      snd.setSinkId(props.selectedSpeaker).then(() => {
        setTestSpeakerButtonState(SpeakerButtonState.Playing);
        snd.addEventListener("ended", (event) =>
          setTestSpeakerButtonState(SpeakerButtonState.Ready)
        );

        snd.play();
      });
    });
  }

  const validSelected =
    props.speakerDevices.find((s) => s.deviceId === props.selectedSpeaker)
      ?.deviceId || "";

  return (
    <React.Fragment>
      <Grid item sx={{ margin: "auto" }} textAlign="center" xs={1}>
        <VolumeUpIcon fontSize="large" />
      </Grid>
      <Grid item xs={9}>
        <FormControl fullWidth>
          <Select onChange={props.onChange} value={validSelected}>
            {props.speakerDevices.map((dev) => (
              <MenuItem value={dev.deviceId} key={dev.deviceId}>
                {dev.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item textAlign={"center"} xs={2}>
        <LoadingButton
          variant="outlined"
          size="large"
          fullWidth
          style={{ height: "100%" }}
          startIcon={<PlayCircleOutlineIcon />}
          onClick={handleTestSoundClick}
          loadingIndicator={t(
            `mediaSelection.card.speaker.test.button.${testSpeakerButtonState}`
          )}
          loading={testSpeakerButtonState !== SpeakerButtonState.Ready}
        >
          {t("mediaSelection.card.speaker.test.button.label")}
        </LoadingButton>
      </Grid>
    </React.Fragment>
  );
}

export interface SpeakerSelectorProps {
  onChange: (change: SelectChangeEvent) => void;
  selectedSpeaker: string;
  speakerDevices: Array<MediaDeviceInfo>;
}
