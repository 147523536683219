import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function Error404() {
  const { t } = useTranslation();

  return (
    <Grid item>
      <Typography variant="h3">{t("error.404.title")}</Typography>
      <Typography paragraph>{t("error.404.description")}</Typography>
    </Grid>
  );
}

export default Error404;
