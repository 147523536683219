/** @jsxImportSource @emotion/react */

import { useEffect } from "react";
import { getCookieConsentValue } from "react-cookie-consent";

const injectThirdParty = (win, doc, el, src, name) => {
  if (name) {
    win[name] =
      win[name] ||
      function () {
        (win[name].q = win[name].q || []).push(arguments);
      };
  }
  const l = doc.createElement(el);
  l.async = 1;
  l.src = src;
  const n = doc.getElementsByTagName(el)[0];
  n.parentNode.insertBefore(l, n);
};

let injected = false;
function ThirdPartyInjector() {
  useEffect(() => {
    const cookieConsentValue = getCookieConsentValue();
    if (!injected && cookieConsentValue === "true") {
      injectThirdParty(
        window,
        document,
        "script",
        "https://assets.mailerlite.com/js/universal.js",
        "ml"
      );
      window.ml("account", "191707");

      injectThirdParty(
        window,
        document,
        "script",
        "https://www.googletagmanager.com/gtag/js?id=G-1MJ6GFBENS"
      );
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-1MJ6GFBENS");
      injected = true;
    }
  }, []);

  return null;
}

export default ThirdPartyInjector;
