import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { Details, FightPreview, FightVideo, theme } from "@tatami-web/shared";
import { useNavigate } from "react-router-dom";

export interface FightPreviewCardProps {
  fight: FightPreview;
}

function FightPreviewCard({ fight }: FightPreviewCardProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      `/fights/view/${encodeURIComponent(fight.title.replaceAll(" ", "_"))}?f=${
        fight.uid
      }`
    );
  };

  return (
    <Card raised sx={{ marginBottom: theme.spacing(4) }}>
      {/* <CardActionArea onClick={handleClick}>
        <CardHeader title={fight.title} subheader={fight.description} />
      </CardActionArea> */}
      <CardMedia src={fight.playbackUrl}>
        <FightVideo
          title={fight.title}
          playbackUrl={fight.playbackUrl}
          leftUserUid={fight.leftUserUid}
          rightUserUid={fight.rightUserUid}
          results={fight.results}
          controls
        />
      </CardMedia>
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Details
            fightMeta={fight}
            leftUserUid={fight.leftUserUid}
            rightUserUid={fight.rightUserUid}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default FightPreviewCard;
