/** @jsxImportSource @emotion/react */

import { Container } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FightAgenda, Transition, TransitionEnded } from "@tatami-web/shared";
import { useRoomContext } from "../RoomContext";
import TimedPhase from "./TimedPhase";

function Agenda(props: AgendaProps) {
  const roomContext = useRoomContext();
  const [followingPhases, setFollowingPhases] = useState(
    props.agenda.phases.slice(2)
  );
  const [activeTransition, setActiveTransition] = useState<Transition | null>(
    null
  );
  const [activePhase, setActivePhase] = useState(props.agenda.phases[0]);

  const onWSMessageHandler: (this: WebSocket, ev: MessageEvent) => any =
    useCallback(
      function (event) {
        const message = JSON.parse(event.data);

        switch (message["_type"]) {
          case "TransitionStarted":
            const transition = message as Transition;
            setActiveTransition(transition);
            break;
          case "TransitionEnded":
            const transitionEnded = message as TransitionEnded;
            setActiveTransition(null);
            setActivePhase(
              props.agenda.phases.find((p) => {
                return p.phase === transitionEnded.to;
              }) || followingPhases[0]
            );
            setFollowingPhases(
              props.agenda.phases.filter((p) => {
                return p.phase !== transitionEnded.to;
              })
            );
            break;
        }
      },
      [props.agenda.phases, followingPhases]
    );

  useEffect(() => {
    roomContext.wsSession.addEventListener("message", onWSMessageHandler);
    return () => {
      roomContext.wsSession.removeEventListener("message", onWSMessageHandler);
    };
  }, [onWSMessageHandler, roomContext.wsSession]);

  if (activeTransition)
    return (
      <Container>
        {activeTransition.from} {"->"} {activeTransition.to}
      </Container>
    );

  return <TimedPhase phase={activePhase} />;
}

interface AgendaProps {
  agenda: FightAgenda;
}

export default Agenda;
