import { LoadingButton } from "@mui/lab";
import { Avatar, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getAuth } from "firebase/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  LoginStatus,
  selectSessionState,
  SessionState,
  useAppSelector,
} from "@tatami-web/shared";
import { useLocation } from "react-router-dom";

const PREFIX = "LoginWidget";

const classes = {
  iconButtonAvatar: `${PREFIX}-iconButtonAvatar`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.iconButtonAvatar}`]: {
    padding: 4,
  },
}));

export default function LoginWidget() {
  const { t } = useTranslation();
  const location = useLocation();

  const sessionState = useAppSelector(selectSessionState);

  const handleLogout = () => {
    getAuth().signOut();
    setAnchorEl(null);
  };

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const currentView = (state: SessionState) => {
    switch (state.status) {
      case LoginStatus.LoggedIn:
        return (
          <StyledGrid item>
            <IconButton
              onClick={handleProfileClick}
              color="inherit"
              className={classes.iconButtonAvatar}
              size="large"
            >
              <Avatar
                alt={state.user?.displayName}
                src={state.user?.profilePictureUrl}
              >
                {state.user?.displayName.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </StyledGrid>
        );
      default:
        return (
          <Grid item>
            <LoadingButton
              component={Link}
              to={`/login?next=${encodeURIComponent(location.pathname)}${
                location.search
              }`}
              variant="contained"
              fullWidth
              style={{ height: "100%" }}
              loading={sessionState.fetching}
            >
              {t("appbar.buttons.login")}
            </LoadingButton>
          </Grid>
        );
    }
  };

  return currentView(sessionState);
}
