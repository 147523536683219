/** @jsxImportSource @emotion/react */

import { Box, Drawer, Grid, Theme, useMediaQuery } from "@mui/material";
import {
  ChatBox,
  Details,
  FightVideo,
  theme,
  useBleacherContext,
} from "@tatami-web/shared";
import React from "react";
import { AppBarOffset } from "../../../../App";

function Bleacher() {
  const { fight, media } = useBleacherContext();
  const hiddenDrawer = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  // const [selectedTab, setSelectedTab] = useState(0);
  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setSelectedTab(newValue);
  // };
  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: theme.breakpoints.values.xl,
          flexGrow: 1,
          p: 3,
          [theme.breakpoints.down("sm")]: {
            p: 0,
          },
        }}
      >
        <Box
          sx={{
            flex: "0 1 auto",
          }}
        >
          <FightVideo
            title={fight.title}
            leftUserUid={media.leftSeatUserUid}
            playbackUrl={media.playbackUrl}
            rightUserUid={media.rightSeatUserUid}
            controls
          />
        </Box>
        <Grid
          container
          alignContent="flex-start"
          alignItems={"stretch"}
          rowSpacing={2}
          // sx={{ marginTop: 0, boxShadow: "    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
          // margin-bottom: 5px;" }}
          sx={{
            marginTop: 0,
            boxShadow: "0 5px 9px -5px grey",
            marginBottom: "8px",
            padding: theme.spacing(1),
          }}
        >
          <Details
            fightMeta={fight}
            leftUserUid={media.leftSeatUserUid}
            rightUserUid={media.rightSeatUserUid}
          />
        </Grid>
        {hiddenDrawer && <ChatBox />}
      </Box>
      {!hiddenDrawer && (
        <Drawer
          anchor={"right"}
          open={true}
          hideBackdrop={true}
          variant="permanent"
          sx={{
            width: "30vw",
          }}
          PaperProps={{
            sx: { width: "30vw" },
          }}
          // onClose={toggleDrawer(anchor, false)}
        >
          <AppBarOffset />
          <ChatBox />
        </Drawer>
      )}
    </React.Fragment>
  );
}

export default Bleacher;
