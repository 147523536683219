/** @jsxImportSource @emotion/react */

import {
  BleacherContextProvider,
  selectBleacher,
  selectChat,
  selectFight,
  useAppSelector,
  useBleacher,
  useQuery,
  ChatContextProvider,
  selectVoteResults,
} from "@tatami-web/shared";
import React, { useRef } from "react";
import { Helmet } from "react-helmet-async";
import Bleacher from "./Bleacher";
import { useParams } from "react-router-dom";

function Main() {
  const query = useQuery();
  const { title } = useParams() as {
    title: string;
  };
  const fightUid = query.get("f");

  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const location = useLocation();

  // const sessionState: SessionState = useAppSelector(
  //   selectSessionState,
  //   shallowEqual
  // );

  const bleacher = useAppSelector(selectBleacher);
  const fight = useAppSelector(selectFight);
  const chat = useAppSelector(selectChat);
  const voting = useAppSelector(selectVoteResults);

  const wsSession = useRef<WebSocket>();

  useBleacher(wsSession, fightUid || undefined);

  return (
    <React.Fragment>
      <Helmet>
        <title>{"Tatami - " + title}</title>
        <meta property="og:title" content={"Tatami - " + title} />
      </Helmet>
      {!wsSession.current || !fight || !bleacher.media ? null : (
        <BleacherContextProvider
          value={{
            wsSession: wsSession.current,
            fight,
            media: bleacher.media,
            chat,
            voting,
          }}
        >
          <ChatContextProvider
            value={{ chat, wsSession: wsSession.current, fight }}
          >
            <Bleacher />
          </ChatContextProvider>
        </BleacherContextProvider>
      )}
    </React.Fragment>
  );
}

export default Main;
