import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import MicIcon from "@mui/icons-material/Mic";

import React from "react";

// const source = audioCtx.createMediaStreamSource(media);
// const analyser = audioCtx.createAnalyser();

// analyser.smoothingTimeConstant = 0.3;
// analyser.fftSize = 1024;

//source.connect(audioCtx.destination);
//        console.log(audioCtx.state);

export default function MicSelector(props: MicSelectorProps) {
  const validSelected =
    props.micDevices.find((s) => s.deviceId === props.selectedMic)?.deviceId ||
    "";
  return (
    <React.Fragment>
      <Grid item sx={{ margin: "auto" }} textAlign="center" xs={1}>
        <MicIcon fontSize="large" />
      </Grid>
      <Grid item xs={11}>
        <FormControl fullWidth>
          <Select onChange={props.onChange} value={validSelected}>
            {props.micDevices.map((dev) => (
              <MenuItem value={dev.deviceId} key={dev.deviceId}>
                {dev.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}

export interface MicSelectorProps {
  onChange: (change: SelectChangeEvent) => void;
  selectedMic: string;
  micDevices: Array<MediaDeviceInfo>;
}
