import {
  Box,
  Chip,
  Drawer,
  Fade,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { ChatBox } from "@tatami-web/shared";
import React from "react";
import { Helmet } from "react-helmet-async";
import { AppBarOffset } from "../../../../App";
import theme from "../../../../theme";
import Agenda from "./agendaTop/Agenda";
import { useRoomContext } from "./RoomContext";
import FighterVideo from "./video/FighterVideo";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export interface FightRoomProps {}

function FightRoom(props: FightRoomProps) {
  const hiddenDrawer = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const roomContext = useRoomContext();
  const fight = roomContext.fight;

  const me = roomContext.room.participants.find(
    (p) => p.sid === roomContext.session.sid
  );
  const oponnent = roomContext.room.participants.find(
    (p) => p.sid !== roomContext.session.sid
  );

  if (!me) return null;

  return (
    <React.Fragment>
      <Helmet>
        <title>Tatami - {fight.title}</title>
      </Helmet>
      <Box
        component="main"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: theme.breakpoints.values.xl,
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
        }}
      >
        <Grid
          item
          container
          xs={12}
          md={12}
          alignContent="flex-start"
          alignItems="stretch"
          sx={{
            flexBasis: "max-content !important",
          }}
        >
          <Grid item xs={12}>
            <Agenda agenda={fight.agenda} />
          </Grid>
          <Grid container item xs={12} sx={{ position: "relative" }}>
            <Fade in={roomContext.live}>
              <Chip
                color="error"
                size="small"
                icon={<FiberManualRecordIcon />}
                label="Live"
                sx={{
                  position: "absolute",
                  zIndex: 1,
                  top: "1em",
                  left: "1em",
                }}
              />
            </Fade>
            <Grid item xs={6}>
              <FighterVideo
                videoEmitterSid={roomContext.session.sid}
                availableActions={roomContext.availableActions}
                participant={me}
              />
            </Grid>
            <Grid item xs={6}>
              {oponnent ? (
                <FighterVideo
                  key={oponnent.sid}
                  videoEmitterSid={oponnent.sid}
                  participant={oponnent}
                />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignContent="flex-start"
          alignItems={"stretch"}
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h4">{fight.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{fight.description}</Typography>
          </Grid>
        </Grid>
        {hiddenDrawer && (
          <Grid
            item
            xs={12}
            sx={{
              // display: "flex",
              overflowY: "auto",
              flexBasis: "100%",
              alignSelf: "stretch",
              // [theme.breakpoints.up("sm")]: {
              height: "100%",
              minHeight: "5em",
              width: "100%",
              // },
            }}
          >
            <ChatBox />
          </Grid>
        )}
      </Box>
      {!hiddenDrawer && (
        <Drawer
          anchor={"right"}
          open={true}
          hideBackdrop={true}
          variant="permanent"
          sx={{
            width: "30vw",
          }}
          PaperProps={{
            sx: { width: "30vw" },
          }}
          // onClose={toggleDrawer(anchor, false)}
        >
          <AppBarOffset />
          <ChatBox />
        </Drawer>
      )}
    </React.Fragment>
  );
}

export default FightRoom;
