import { Button, FormControlLabel, Switch } from "@mui/material";
import { Participant, UserSwitchAction } from "@tatami-web/domain";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRoomContext } from "../RoomContext";

function SwitchAction(props: SwitchActionProps) {
  const { t } = useTranslation();
  const roomContext = useRoomContext();
  const switchRef = useRef<HTMLButtonElement | null>(null);

  const handleSwitchChange = (
    event: React.SyntheticEvent,
    checked: boolean
  ) => {
    // Ugly workaround because we have the button wrapping the switch
    event.preventDefault();
    if (checked !== props.participant.switches[props.action.field])
      roomContext.wsSession.send(
        JSON.stringify(Object.assign({}, props.action, { state: checked }))
      );
  };

  const handleButtonClick = (event: React.SyntheticEvent) => {
    switchRef.current?.click();
  };

  return (
    <Button onClick={handleButtonClick} color="secondary">
      <FormControlLabel
        control={
          <Switch
            checked={props.participant.switches[props.action.field]}
            onClick={() => {}}
            onChange={handleSwitchChange}
            name="ready"
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            readOnly={props.readOnly || false}
            ref={switchRef}
          />
        }
        labelPlacement="bottom"
        label={t(`fight.room.lobby.video.toolbar.action.${props.action.field}`)}
      ></FormControlLabel>
    </Button>
  );
}

interface SwitchActionProps {
  action: UserSwitchAction;
  participant: Participant;
  readOnly?: boolean;
}

export default SwitchAction;
