import Typography from "@mui/material/Typography";
import { Route, Routes } from "react-router-dom";
import Error404 from "../errors/Error404";
import CreateFightForm from "./fight/create/CreateFightForm";
import FightDone from "./fight/done/FightDone";

import FightMain from "./fight/room/Main";
import ViewMain from "./view/bleacher/Main";

function Fights() {
  return (
    <Routes>
      <Route path={`fight/create`} element={<CreateFightForm />} />
      <Route path={`fight/:uid/finished`} element={<FightDone />} />
      <Route path={`fight/:title`} element={<FightMain />} />
      <Route path={`view/:title`} element={<ViewMain />} />
      <Route
        path="/"
        element={
          <div>
            <Typography paragraph>FIGHTS</Typography>
          </div>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default Fights;
