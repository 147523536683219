/** @jsxImportSource @emotion/react */

import { JoinFight, LoadFight } from "@tatami-web/domain";
import {
  ChatContextProvider,
  selectChat,
  selectFight,
  useQuery,
} from "@tatami-web/shared";
import { useCallback, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import MediaSelection from "../../../../components/media/MediaSelection";
import {
  availableActionsChanged,
  chatMessageReceived,
  recordingStateChanged,
  fightError,
  fightLoaded,
  fightParticipantsChanged,
  fightRoomJoined,
  fightRoomWSConnected,
  navigationLeft,
  selectFightRoom,
  MediaDevicesValues,
  selectDeviceSelection,
  selectSessionState,
  SessionState,
} from "@tatami-web/shared";
import FightRoom from "./FightRoom";
import Loading from "./Loading";
import { RoomContextProvider } from "./RoomContext";

function Main() {
  const query = useQuery();
  const fightUid = query.get("f");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const sessionState: SessionState = useSelector(
    selectSessionState,
    shallowEqual
  );

  const devicesSelected = useSelector(selectDeviceSelection);

  const fightRoom = useSelector(selectFightRoom);
  const fight = useSelector(selectFight);
  const chat = useSelector(selectChat);

  const wsSession = useRef<WebSocket>();

  const onWSOpenHandler: (this: WebSocket, ev: Event) => any = useCallback(
    function () {
      if (!fightUid) return;
      dispatch(fightRoomWSConnected());
      this.send(JSON.stringify(new LoadFight(fightUid)));
    },
    [dispatch, fightUid]
  );

  const onWSErrorHandler: (this: WebSocket, ev: Event) => any = useCallback(
    function () {
      navigate(`/error/ConnectionError`, { replace: false });
    },
    [navigate]
  );

  const onWSCloseHandler: (this: WebSocket, ev: CloseEvent) => any =
    useCallback(
      function (ev) {
        if (ev.code !== 1000) {
          console.warn(
            "The Websocket connection was closed unexpectedly",
            ev.reason
          );
          navigate(`/error/ConnectionError`, { replace: false });
        }
      },
      [navigate]
    );

  const onWSMessageHandler: (this: WebSocket, ev: MessageEvent) => any =
    useCallback(
      function (event) {
        const message = JSON.parse(event.data);

        switch (message["_type"]) {
          case "FightNotFoundResult":
            navigate(`/error/404`);
            break;
          case "FightFoundResult":
            dispatch(fightLoaded(message));
            if (fightUid && sessionState.sid && sessionState.user?.uid)
              this.send(
                JSON.stringify(
                  new JoinFight(fightUid, sessionState.sid, sessionState.user)
                )
              );
            break;
          case "FightJoinedResponse":
            dispatch(fightRoomJoined());
            break;
          case "ParticipantsChanged":
            dispatch(fightParticipantsChanged(message));
            break;
          case "AvailableActions":
            dispatch(availableActionsChanged(message));
            break;
          case "FightErrorMessage":
            dispatch(fightError(message));
            break;
          case "FightFinished":
            // setOnAir(false);
            navigate(`/fights/fight/${fightUid}/finished`);
            break;
          case "ChatMessageReceived":
            const chatMessage = message.message;
            dispatch(chatMessageReceived(chatMessage));
            break;
          case "RecordingStateChanged":
            dispatch(recordingStateChanged(message.state));
            break;
        }
      },
      // eslint-disable-next-line
      [
        fightUid,
        sessionState.sid,
        // eslint-disable-next-line
        JSON.stringify(sessionState.user),
        dispatch,
        navigate,
      ]
    );

  useEffect(() => {
    if (!fightUid || !sessionState.sid || !sessionState.user?.uid) return;
    wsSession.current = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_BASE_URL}/fight`
    );

    wsSession.current.addEventListener("message", onWSMessageHandler);
    wsSession.current.addEventListener("open", onWSOpenHandler);
    wsSession.current.addEventListener("error", onWSErrorHandler);
    // First, an unexpected close of the connection is considered an error, in the cleaning, we remove the listener as the close is expected then
    wsSession.current.addEventListener("close", onWSCloseHandler);

    return () => {
      if (
        wsSession.current &&
        wsSession.current.readyState !== WebSocket.CLOSING &&
        wsSession.current.readyState !== WebSocket.CLOSED
      ) {
        wsSession.current.removeEventListener("message", onWSMessageHandler);
        wsSession.current.removeEventListener("open", onWSOpenHandler);
        wsSession.current.removeEventListener("error", onWSErrorHandler);

        if (wsSession.current.readyState === WebSocket.CONNECTING) {
          wsSession.current.addEventListener("open", function () {
            this.close(1000, "Cleaning up effect");
          });
        } else {
          wsSession.current.close(1000, "Cleaning up effect");
        }
      }
      wsSession.current = undefined;
      dispatch(navigationLeft());
    };
  }, [
    fightUid,
    sessionState.sid,
    sessionState.user?.uid,
    onWSMessageHandler,
    onWSOpenHandler,
    onWSErrorHandler,
    onWSCloseHandler,
    dispatch,
  ]);

  const loadingSteps: Array<boolean> = [
    sessionState.fetching,
    !wsSession.current,
    !(wsSession.current?.readyState === WebSocket.OPEN),
    !fight,
    !fightRoom.joined,
    !fightRoom.room,
  ];

  const fulfilment =
    (loadingSteps.filter((i) => !i).length / loadingSteps.length) * 100;

  if (sessionState.fetching) {
    return <Loading fulfilment={fulfilment} />;
  } else if (!sessionState.sid || !sessionState.user) {
    return (
      <Navigate
        to={`/login?next=${location.pathname}${location.search}`}
        replace={false}
      />
    );
  } else if (
    !wsSession.current ||
    !(wsSession.current?.readyState === WebSocket.OPEN) ||
    !fight ||
    !fightRoom.joined ||
    !fightRoom.room
  ) {
    return <Loading fulfilment={fulfilment} />;
  } else if (
    ![
      devicesSelected.selectedCam,
      devicesSelected.selectedMic,
      devicesSelected.selectedSpeaker,
    ].every((d) => typeof d === "string")
  ) {
    return <MediaSelection />;
  } else {
    return (
      <RoomContextProvider
        value={{
          wsSession: wsSession.current,
          fight: fight,
          room: fightRoom.room,
          live: fightRoom.live,
          chat: chat,
          session: { sid: sessionState.sid, user: sessionState.user },
          availableActions: fightRoom.availableActions,
          deviceSelection: devicesSelected as MediaDevicesValues, //This is check already in line 187 to 191
        }}
      >
        <ChatContextProvider
          value={{ chat, wsSession: wsSession.current, fight: fight }}
        >
          <FightRoom />
        </ChatContextProvider>
      </RoomContextProvider>
    );
  }
}

export default Main;
