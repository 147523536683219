import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

//const useStyles = makeStyles((theme: Theme) => createStyles({}));

function FightDone() {
  const { t } = useTranslation();

  const { uid } = useParams() as {
    uid: string;
  };

  const tweet = t("fight.room.fight.share.tweet", {
    url: `https://${process.env.REACT_APP_WEB_HOST}/fights/view/Fight?f=${uid}`,
  });

  return (
    <Container>
      <Typography component="h2" variant="h2">
        {t("fight.room.fight.done.thank")}
      </Typography>
      <Typography>{t("fight.room.fight.done.share")}</Typography>
      <p>
        <a
          href={encodeURI(`https://twitter.com/intent/tweet?text=${tweet}`)}
          className="twitter-share-button"
          data-hashtags="tatami"
          target="_blank"
          rel="noreferrer"
        >
          Tweet
        </a>
      </p>
    </Container>
  );
}

export default FightDone;
