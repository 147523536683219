import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app

// src/ui/theme/index.js

const palette = {
  background: {
    //default: "#f59e42",
  },
  primary: { main: "#6bc9df" },
  secondary: { main: "#de9554" },
  third: {
    main: "#ca81de",
  },
};

const components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: grey[200],
      },
    },
  },
};

export const theme = createTheme({
  components,
  palette,
});

declare module "@mui/material/styles" {
  interface Palette {
    third: Palette["primary"];
  }
  interface PaletteOptions {
    third: PaletteOptions["primary"];
  }

  interface PaletteColor {
    darker?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
  }
}

// Update the Button's color prop options
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    third: true;
  }
}
export default theme;
