import {
  ICECandidate,
  ICECandidateOrigin,
  SdpAnswer,
  SdpOffer,
} from "@tatami-web/domain";
import {
  IceServers,
  MediaDevicesValues,
  useAppDispatch,
  cleanDeviceSelection,
} from "@tatami-web/shared";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export enum WebRTCDirection {
  RecieveOnly = "RecieveOnly",
  SendReceive = "SendReceive",
  SendOnly = "SendOnly",
}

export interface UseRtcPeerConnectionOptions {
  iceServers: IceServers;
  videoEmitterSid: string;
  direction: WebRTCDirection;
  deviceSelection: MediaDevicesValues;
}

function setMediaBitrate(
  sdp: string,
  media: "video" | "audio",
  bitrate: number
) {
  var lines = sdp.split("\n");
  var line = -1;
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].indexOf("m=" + media) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    console.warn("Could not find the m line for", media);
    return sdp;
  }
  console.debug("Found the m line for", media, "at line", line);

  // Pass the m line
  line++;

  // Skip i and c lines
  while (lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
    line++;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf("b") === 0) {
    console.debug("Replaced b line at line", line);
    lines[line] = "b=AS:" + bitrate;
    return lines.join("\n");
  }

  // Add a new b line
  console.debug("Adding new b line before line", line);
  var newLines = lines.slice(0, line);
  newLines.push("b=AS:" + bitrate);
  newLines = newLines.concat(lines.slice(line, lines.length));
  return newLines.join("\n");
}

const mediaConstraints: (
  micDeviceId: string,
  camDeviceId: string
) => MediaStreamConstraints = (mic, cam) => {
  return {
    audio: Object.assign(
      {
        echoCancellation: true,
      },
      mic ? { deviceId: mic } : {}
    ),
    video: Object.assign(
      {
        width: {
          ideal: 640,
        },
        height: {
          ideal: 720,
        },
        aspectRatio: {
          exact: 0.88888889,
        },
        frameRate: {
          ideal: 30,
        },
      },
      cam ? { deviceId: cam } : {}
    ),
  };
};

export function useRtcPeerConnection(
  videoRef: RefObject<HTMLVideoElement>,
  signalling: WebSocket,
  config: UseRtcPeerConnectionOptions
) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const peerConnection = useRef<RTCPeerConnection>();
  const tracksRef = useRef<Array<MediaStreamTrack>>();

  const [peerConnectionState, setPeerConnectionState] = useState<
    RTCPeerConnectionState | undefined
  >(peerConnection.current?.connectionState);

  const onConnectionStateChange = useCallback<
    (this: RTCPeerConnection, ev: Event) => any
  >(function change(event: Event) {
    setPeerConnectionState(this.connectionState);
  }, []);

  const onTrack = useCallback(
    (event: RTCTrackEvent) => {
      if (videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = event.streams[0];
        videoRef.current
          .play()
          .then(() => {
            if (peerConnection.current) {
              const sender = peerConnection.current.getSenders()[0];
              const parameters = sender.getParameters();
              if (!parameters.encodings) {
                parameters.encodings = [{}];
              }
              parameters.encodings[0].maxBitrate = 2.5 * 1000 * 1000; //Docs says this is bps (https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpEncodingParameters/maxBitrate)
              parameters.encodings[0].priority = "high";
              sender
                .setParameters(parameters)
                .then(() => {
                  console.log("Done!");
                })
                .catch((e) => console.error(e));
            }
          })
          .catch((e) => {
            console.error("Failed playing video", e);
          });
      }
    },
    [videoRef]
  );

  const onNegotiationNeeded = useCallback(
    (event: Event) => {
      const negotiate = async () => {
        if (peerConnection.current?.localDescription) return;
        const offer = await peerConnection.current?.createOffer();
        if (offer?.sdp) {
          offer.sdp = setMediaBitrate(offer.sdp, "video", 2.5 * 1000 * 1000); //Docs says this is kbps (https://www.rfc-editor.org/rfc/rfc3556#section-2)
          await peerConnection.current?.setLocalDescription(offer);
          signalling.send(
            JSON.stringify(new SdpOffer(config.videoEmitterSid, offer.sdp))
          );
        }
      };
      negotiate();
    },
    [config.videoEmitterSid, signalling]
  );

  const addMediaCallback = useCallback(() => {
    const addUserMedia = async (conn?: RTCPeerConnection) => {
      if (config.direction === WebRTCDirection.SendReceive) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia(
            mediaConstraints(
              config.deviceSelection.selectedMic,
              config.deviceSelection.selectedCam
            )
          );
          const tracks = stream.getTracks();
          tracksRef.current = tracks;
          tracks.forEach((track) => {
            conn?.addTrack(track, stream);
          });
        } catch (error) {
          if (error instanceof DOMException) {
            const domE = error as DOMException;
            if (domE.name === "NotFoundError") {
              console.warn(
                "Some of the selected input devices was not found",
                domE
              );
              dispatch(cleanDeviceSelection());
            }
          } else {
            console.error(
              "Error trying to get stream from user media and adding it to the PeerConnection",
              error
            );
            navigate(`/error/GetMediaError`, { replace: false });
          }
        }
      }
    };
    addUserMedia(peerConnection.current || undefined).catch((error) => {
      console.error("ERROR", error);
    });
  }, [
    config.deviceSelection.selectedMic,
    config.deviceSelection.selectedCam,
    config.direction,
    dispatch,
    navigate,
  ]);

  const onIceCandidate = useCallback(
    (iceEvent: RTCPeerConnectionIceEvent) => {
      if (iceEvent && iceEvent.candidate) {
        signalling.send(
          JSON.stringify(
            new ICECandidate(
              config.videoEmitterSid,
              ICECandidateOrigin.Client,
              iceEvent.candidate
            )
          )
        );
      }
    },
    [config.videoEmitterSid, signalling]
  );

  const onWSMessageHandler = useCallback(
    (event: MessageEvent) => {
      const message = JSON.parse(event.data);
      if (message["emitter"] !== config.videoEmitterSid) return;

      switch (message["_type"]) {
        case "SDPAnswer":
          const answer = new SdpAnswer(message.emitter, message.value);
          peerConnection.current?.setRemoteDescription({
            sdp: answer.value,
            type: answer.sdpType,
          });
          break;
        case "ICECandidate":
          console.debug(
            `ICE candidate for emitter ${config.videoEmitterSid} received`
          );
          const candidate = new ICECandidate(
            message.emitter,
            message.origin,
            message.candidate
          );
          peerConnection.current
            ?.addIceCandidate(new RTCIceCandidate(candidate.candidate))
            .catch((error) => {
              console.error("Error adding ice candidate", error);
            });
          break;
        default:
          console.warn(`Unexpected message from server: ${event.data}`);
      }
    },
    [config.videoEmitterSid]
  );

  useEffect(() => {
    if (config.iceServers && !peerConnection.current) {
      peerConnection.current = new RTCPeerConnection({
        iceServers: [config.iceServers],
      });
      peerConnection.current?.addEventListener(
        "connectionstatechange",
        onConnectionStateChange
      );
      peerConnection.current.addEventListener("track", onTrack);
      peerConnection.current.addEventListener("icecandidate", onIceCandidate);
      peerConnection.current.addEventListener(
        "negotiationneeded",
        onNegotiationNeeded
      );
      if (config.direction === WebRTCDirection.RecieveOnly) {
        peerConnection.current.addTransceiver("audio", {
          direction: "recvonly",
        });
        peerConnection.current.addTransceiver("video", {
          direction: "recvonly",
        });
      } else if (config.direction === WebRTCDirection.SendReceive) {
        addMediaCallback();
      }
    }
  }, [
    config.iceServers,
    config.direction,
    onIceCandidate,
    onTrack,
    onNegotiationNeeded,
    addMediaCallback,
    onConnectionStateChange,
  ]);

  useEffect(() => {
    signalling.addEventListener("message", onWSMessageHandler);
    return () => {
      signalling.removeEventListener("message", onWSMessageHandler);
    };
  }, [signalling, onWSMessageHandler]);

  useEffect(() => {
    return () => {
      if (tracksRef.current) {
        tracksRef.current.forEach((t) => t.stop());
        tracksRef.current = undefined;
      }
      peerConnection.current?.close();
    };
  }, []);

  return peerConnectionState;
}
