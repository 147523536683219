import React from "react";
import { AvailableActions, Chat, Fight, FightRoom } from "@tatami-web/shared";
import { MediaDevicesValues } from "@tatami-web/shared";
import { ActiveSession } from "@tatami-web/shared";

interface RoomContextValues {
  wsSession: WebSocket;
  fight: Fight;
  room: FightRoom;
  live: boolean;
  chat: Chat;
  session: ActiveSession;
  availableActions: AvailableActions;
  deviceSelection: MediaDevicesValues;
}

const RoomContext = React.createContext<RoomContextValues | undefined>(
  undefined
);
export const RoomContextProvider = RoomContext.Provider;

export const useRoomContext = () => {
  const context = React.useContext(RoomContext);
  if (context === undefined) {
    throw new Error("useRoomContext must be used within a RoomContextProvider");
  }
  return context;
};

export default RoomContext;
