import { Zoom } from "@mui/material";
import { Participant } from "@tatami-web/domain";
import React from "react";
import { AvailableActions } from "@tatami-web/shared";
import FighterAction from "./FighterAction";

function FighterActions(props: FighterActionsProps) {
  return (
    <React.Fragment>
      {props.availableActions &&
        Object.keys(props.availableActions.fighter).map((action, i) => {
          return (
            <Zoom
              key={props.availableActions.fighter[action].action._type}
              in={true}
              timeout={100}
              style={{
                transitionDelay: `${i * 200 + 200}ms`,
              }}
              unmountOnExit
            >
              <span>
                <FighterAction
                  key={action}
                  action={props.availableActions.fighter[action].action}
                  i={i}
                  participant={props.participant}
                />
              </span>
            </Zoom>
          );
        })}
    </React.Fragment>
  );
}
interface FighterActionsProps {
  availableActions: AvailableActions;
  participant: Participant;
}

export default FighterActions;
