export var AsyncState;
(function (AsyncState) {
    AsyncState["Unitialized"] = "Unitialized";
    AsyncState["Fetching"] = "Fetching";
    AsyncState["Failed"] = "Failed";
    AsyncState["Completed"] = "Completed";
})(AsyncState || (AsyncState = {}));
var FreeFightRules = /** @class */ (function () {
    function FreeFightRules(maxDuration, introductionDuration, closeDuration) {
        this._type = "FreeFightRules";
        this.maxDuration = maxDuration;
        this.introductionDuration = introductionDuration;
        this.closeDuration = closeDuration;
    }
    return FreeFightRules;
}());
export { FreeFightRules };
var ChessFightRules = /** @class */ (function () {
    function ChessFightRules(maxSpeechDuration, introductionDuration, closeDuration) {
        this._type = "ChessFightRules";
        this.maxSpeechDuration = maxSpeechDuration;
        this.introductionDuration = introductionDuration;
        this.closeDuration = closeDuration;
    }
    return ChessFightRules;
}());
export { ChessFightRules };
export var FightModerationMode;
(function (FightModerationMode) {
    FightModerationMode["Free"] = "Free";
    FightModerationMode["Chess"] = "Chess";
})(FightModerationMode || (FightModerationMode = {}));
var CreateFightCommand = /** @class */ (function () {
    function CreateFightCommand(title, description, lang, mode, rules) {
        this._type = "CreateFight";
        this.title = title;
        this.description = description;
        this.lang = lang;
        this.mode = mode;
        this.rules = rules;
    }
    return CreateFightCommand;
}());
export { CreateFightCommand };
var LoadFight = /** @class */ (function () {
    function LoadFight(uid) {
        this._type = "LoadFight";
        this.uid = uid;
    }
    return LoadFight;
}());
export { LoadFight };
export var LoadVoting = {
    _type: "LoadVoting",
};
var JoinFight = /** @class */ (function () {
    function JoinFight(fightUid, sid, user) {
        this._type = "JoinFight";
        this.fightUid = fightUid;
        this.sid = sid;
        this.user = user;
    }
    return JoinFight;
}());
export { JoinFight };
export var ReadyForTurn = {
    _type: "ReadyForTurn",
};
var SdpOffer = /** @class */ (function () {
    function SdpOffer(emitter, value) {
        this._type = "SDPOffer";
        this.emitter = emitter;
        this.value = value;
        this.sdpType = "offer";
    }
    return SdpOffer;
}());
export { SdpOffer };
var SdpAnswer = /** @class */ (function () {
    function SdpAnswer(emitter, value) {
        this._type = "SdpAnswer";
        this.emitter = emitter;
        this.value = value;
        this.sdpType = "answer";
    }
    return SdpAnswer;
}());
export { SdpAnswer };
export var ICECandidateOrigin;
(function (ICECandidateOrigin) {
    ICECandidateOrigin["Server"] = "Server";
    ICECandidateOrigin["Client"] = "Client";
})(ICECandidateOrigin || (ICECandidateOrigin = {}));
var ICECandidate = /** @class */ (function () {
    function ICECandidate(emitter, origin, candidate) {
        this._type = "ICECandidate";
        this.emitter = emitter;
        this.origin = origin;
        this.candidate = candidate;
    }
    return ICECandidate;
}());
export { ICECandidate };
export var RecordingState;
(function (RecordingState) {
    RecordingState["Recording"] = "Recording";
    RecordingState["StandBy"] = "StandBy";
    RecordingState["Failed"] = "Failed";
    RecordingState["Preparing"] = "Preparing";
    RecordingState["Paused"] = "Paused";
    RecordingState["Stopped"] = "Stopped";
})(RecordingState || (RecordingState = {}));
export var Interrupt = {
    _type: "Interrupt",
    kind: "Button",
};
export var EndSpeech = {
    _type: "EndSpeech",
    kind: "Button",
};
var SendChatMessage = /** @class */ (function () {
    function SendChatMessage(text) {
        this._type = "SendChatMessage";
        this.text = text;
    }
    return SendChatMessage;
}());
export { SendChatMessage };
var Vote = /** @class */ (function () {
    function Vote(fighterUid) {
        this._type = "Vote";
        this.fighterUid = fighterUid;
    }
    return Vote;
}());
export { Vote };
var Unvote = /** @class */ (function () {
    function Unvote(fighterUid) {
        this._type = "Unvote";
        this.fighterUid = fighterUid;
    }
    return Unvote;
}());
export { Unvote };
