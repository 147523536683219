import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import {
  FeedRequest,
  fetchFights,
  FightPreview,
  resetHome,
  selectHomeState,
  useAppDispatch,
} from "@tatami-web/shared";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FightPreviewCard from "./FightPreviewCard";

const pageSize = 2;

type PromiseType = Promise<
  | PayloadAction<
      FightPreview[],
      string,
      { arg: FeedRequest; requestId: string; requestStatus: "fulfilled" },
      never
    >
  | PayloadAction<
      unknown,
      string,
      {
        arg: FeedRequest;
        requestId: string;
        requestStatus: "rejected";
        aborted: boolean;
        condition: boolean;
      } & ({ rejectedWithValue: true } | ({ rejectedWithValue: false } & {})),
      SerializedError
    >
> & {
  abort: (reason?: string | undefined) => void;
  requestId: string;
  arg: FeedRequest;
  unwrap: () => Promise<FightPreview[]>;
};

function Home() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const homeState = useSelector(selectHomeState);

  useEffect(() => {
    let fetchPromise: PromiseType;

    fetchPromise = dispatch(
      fetchFights({
        limit: pageSize,
      })
    );

    return () => {
      fetchPromise?.abort();
      dispatch(resetHome());
    };
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!homeState.fetching)
      dispatch(
        fetchFights({
          before:
            homeState.fights.length > 0
              ? homeState.fights[homeState.fights.length - 1].createdAt
              : undefined,
          limit: pageSize,
        })
      );
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        flexGrow: 1,
        p: 3,
      }}
    >
      <Container maxWidth="md" disableGutters>
        <Typography variant="h4" gutterBottom>
          {t("home.latest.header")}
        </Typography>
        {homeState.fights.map((f) => {
          return <FightPreviewCard key={f.uid} fight={f} />;
        })}
        <Box textAlign="center">
          <Button
            variant="outlined"
            onClick={handleLoadMore}
            size="small"
            href="#contained-buttons"
          >
            {t("common.button.loadMore")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Home;
