import DoneIcon from "@mui/icons-material/Done";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { Button, FormControlLabel } from "@mui/material";
import {
  EndSpeech,
  Interrupt,
  Participant,
  UserAction,
  UserSwitchAction,
} from "@tatami-web/domain";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRoomContext } from "../RoomContext";
import SwitchAction from "./SwitchAction";

const iconForAction = (action: UserAction): React.ReactElement => {
  switch (action._type) {
    case Interrupt._type:
      return <RecordVoiceOverIcon />;
    case EndSpeech._type:
      return <DoneIcon />;
    default:
      return <span></span>;
  }
};

function FighterAction(props: FighterActionProps) {
  const { t } = useTranslation();
  const roomContext = useRoomContext();

  const handleButtonActionClicked = () => {
    roomContext.wsSession.send(JSON.stringify(props.action));
  };

  const actionEl = (): JSX.Element => {
    switch (props.action.kind) {
      case "Switch":
        const switchAction = props.action as UserSwitchAction;
        return (
          <SwitchAction action={switchAction} participant={props.participant} />
        );
      case "Button":
      default:
        return (
          <Button onClick={handleButtonActionClicked} color="secondary">
            <FormControlLabel
              control={iconForAction(props.action)}
              labelPlacement="bottom"
              label={t(
                `fight.room.lobby.video.toolbar.action.${props.action._type}`
              )}
              //label={"End Speech"}
            ></FormControlLabel>
          </Button>
        );
    }
  };

  return actionEl();
}

interface FighterActionProps {
  action: UserAction;
  i: number;
  participant: Participant;
}

export default FighterAction;
