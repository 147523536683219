import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export interface StateMessageProps {
  message: string;
  icon: JSX.Element;
}

function StateMessage(props: StateMessageProps) {
  return (
    <Box
      sx={{
        minHeight: "40vh",
        height: "100%",
        backgroundColor: grey[200],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      <Typography>{props.message}</Typography>
      {props.icon}
    </Box>
  );
}

export default StateMessage;
