import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { grey } from "@mui/material/colors";

import merge from "lodash/merge";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  MediaDevicesState,
  selectDeviceSelection,
  setDeviceSelection,
  useAppDispatch,
  useAppSelector,
} from "@tatami-web/shared";
import theme from "../../theme";
import CamSelector from "./CamSelector";
import MicSelector from "./MicSelector";
import SpeakerSelector from "./SpeakerSelector";

async function getConnectedDevices(
  kind: MediaDeviceKind
): Promise<Array<MediaDeviceInfo>> {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter((device) => device.kind === kind);
}

const commonMediaConstraints = {
  video: {
    width: {
      min: 320,
      max: 960,
    },
    height: {
      min: 480,
      max: 1080,
    },
    aspectRatio: {
      exact: 0.67,
    },
    frameRate: 30,
    facingMode: "user",
  },
};

function MediaSelection() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const selectedDevices: MediaDevicesState = useAppSelector(
    selectDeviceSelection
  );

  const videoRef = useRef<HTMLVideoElement>(null);

  const [permissionEnsured, setPermissionEnsured] = useState<boolean>(false);

  const [videoDevices, setVideoDevices] = useState<Array<MediaDeviceInfo>>([]);
  const [micDevices, setMicDevices] = useState<Array<MediaDeviceInfo>>([]);
  const [speakerDevices, setSpeakerDevices] = useState<Array<MediaDeviceInfo>>(
    []
  );

  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string>(
    selectedDevices.selectedCam || ""
  );
  const [selectedMicDevice, setSelectedMicDevice] = useState<string>(
    selectedDevices.selectedMic || ""
  );
  const [selectedSpeakerDevice, setSelectedSpeakerDevice] = useState<string>(
    selectedDevices.selectedSpeaker || "default"
  );

  const getMedia = useCallback(async (constraints: object) => {
    return await navigator.mediaDevices.getUserMedia(constraints);
  }, []);

  useEffect(() => {
    async function getAllMediaPermissions() {
      return getMedia(
        merge({
          audio: {},
          video: {},
        })
      );
    }
    getAllMediaPermissions()
      .then(() => {
        setPermissionEnsured(true);
      })
      .catch((err) => {
        navigate(`/error/noMediaPermission`, { replace: false });
        console.error(err);
        return null;
      });
  }, [getMedia, navigate]);

  useEffect(() => {
    const reloadAllDevices = () => {
      getConnectedDevices("videoinput").then(setVideoDevices);
      getConnectedDevices("audioinput").then(setMicDevices);
      getConnectedDevices("audiooutput").then(setSpeakerDevices);
    };
    navigator.mediaDevices.addEventListener("devicechange", reloadAllDevices);
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        reloadAllDevices
      );
    };
  });

  useEffect(() => {
    if (!permissionEnsured) return;
    async function getVideoMedia() {
      return getMedia(
        merge(commonMediaConstraints, {
          audio: false,
          video: {
            deviceId:
              selectedVideoDevice !== ""
                ? { exact: selectedVideoDevice }
                : undefined,
          },
        })
      );
    }

    getConnectedDevices("videoinput").then(setVideoDevices);

    getVideoMedia().then((media) => {
      if (media) {
        const videoTrack = media.getTracks().find((t) => t.kind === "video");
        setSelectedVideoDevice(videoTrack?.getSettings().deviceId || "");
        if (videoRef.current) videoRef.current.srcObject = media;
      }
    });
  }, [selectedVideoDevice, getMedia, permissionEnsured]);

  useEffect(() => {
    let audioTrack: MediaStreamTrack | undefined;
    if (!permissionEnsured) return;
    async function getAudioInMedia() {
      return getMedia({
        audio: {
          deviceId:
            selectedMicDevice !== "" ? { exact: selectedMicDevice } : undefined,
        },
        video: false,
      });
    }

    getConnectedDevices("audioinput").then(setMicDevices);
    getConnectedDevices("audiooutput").then(setSpeakerDevices);

    getAudioInMedia().then((media) => {
      if (media) {
        audioTrack = media.getTracks().find((t) => t.kind === "audio");
        setSelectedMicDevice(audioTrack?.getSettings().deviceId || "");
      }
    });
    return () => {
      audioTrack?.stop();
    };
  }, [selectedMicDevice, getMedia, permissionEnsured]);

  useEffect(() => {
    if (!permissionEnsured) return;
    getConnectedDevices("audiooutput").then(setSpeakerDevices);
  }, [selectedSpeakerDevice, permissionEnsured]);

  const handleChange = (setter: Dispatch<SetStateAction<string>>) => {
    return (change: SelectChangeEvent) => setter(change.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      setDeviceSelection({
        selectedMic: selectedMicDevice,
        selectedCam: selectedVideoDevice,
        selectedSpeaker: selectedSpeakerDevice,
      })
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        backgroundColor: grey[900],
        p: 2,
      }}
    >
      <Container maxWidth="lg">
        <Card>
          <CardHeader title={t("mediaSelection.card.title")} />
          <CardContent>
            <Grid container alignItems="flex-start" p={0} spacing={2}>
              <Grid item xs={12} sm={3}>
                <video
                  style={{
                    width: "100%",
                  }}
                  disablePictureInPicture
                  controls={false}
                  ref={videoRef}
                  playsInline
                  autoPlay
                ></video>
              </Grid>

              <Grid
                container
                justifyContent={"center"}
                item
                xs={12}
                sm={9}
                spacing={2}
              >
                <CamSelector
                  selectedCam={selectedVideoDevice}
                  camDevices={videoDevices}
                  onChange={handleChange(setSelectedVideoDevice)}
                />

                <MicSelector
                  selectedMic={selectedMicDevice}
                  micDevices={micDevices}
                  onChange={handleChange(setSelectedMicDevice)}
                />
                <SpeakerSelector
                  selectedSpeaker={selectedSpeakerDevice}
                  speakerDevices={speakerDevices}
                  onChange={handleChange(setSelectedSpeakerDevice)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions
            sx={{
              padding: theme.spacing(2),
            }}
          >
            <Button
              variant="contained"
              size="large"
              disabled={
                selectedMicDevice === "" ||
                selectedVideoDevice === "" ||
                selectedSpeakerDevice === ""
              }
              onClick={handleSubmit}
            >
              {t("mediaSelection.card.actions.save.button.label")}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Box>
  );
}

export default MediaSelection;
