// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { connectAuthEmulator, getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5_1fr45qcvmHyZeIh_zgDJDQ5XHYzn6M",
  authDomain: "vs-show.firebaseapp.com",
  databaseURL: "https://vs-show.firebaseio.com",
  projectId: "vs-show",
  storageBucket: "vs-show.appspot.com",
  messagingSenderId: "580801454004",
  appId: "1:580801454004:web:35abd8c2c9219977cb5ce1",
};

// Initialize Firebase
export const register = () => {
  initializeApp(firebaseConfig);
  if (process.env.NODE_ENV === "development") {
    const auth = getAuth();
    connectAuthEmulator(auth, "http://tatami.local:9099", {
      disableWarnings: true,
    });
  }
};
