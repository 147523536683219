import { useQuery } from "@tatami-web/shared";
import { useNavigate } from "react-router-dom";
import Login from "../../components/login/Login";

function LoginPage() {
  const query = useQuery();
  const next = query.get("next");

  const navigate = useNavigate();

  const handleSwitch = () => {
    navigate("/register");
  };

  const handleSuccess = () => {
    navigate("/");
  };

  return (
    <Login
      nextUrl={next ? next : undefined}
      onSwitch={handleSwitch}
      onLoggedIn={next ? undefined : handleSuccess}
    />
  );
}

export default LoginPage;
