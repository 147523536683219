/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Box, Container, Grid, LinearProgress, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ReactComponent as LogoLoading } from "../../../../logo-loading.svg";
import theme from "../../../../theme";
import "./Loading.css";

export interface LoadingProps {
  fulfilment?: number;
}

function Loading(props: LoadingProps) {
  const variant =
    typeof props.fulfilment !== "undefined" ? "determinate" : "indeterminate";
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        backgroundColor: grey[900],
        p: 2,
      }}
    >
      <Container maxWidth="sm">
        <Paper>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={2} sm={4} />
            <Grid
              item
              xs={8}
              sm={4}
              css={css`
                text-align: center;
              `}
            >
              <p>
                <LogoLoading />
              </p>
            </Grid>
            <Grid item xs={2} sm={4} />
            <Grid item xs={2} sm={4} />
            <Grid item xs>
              <h4>Loading... </h4>
              <p>
                <LinearProgress
                  color="primary"
                  css={css`
                    height: 0.5em;
                    border-radius: 1em;
                  `}
                  variant={variant}
                  value={props.fulfilment}
                />
              </p>
            </Grid>
            <Grid item xs={2} sm={4} />
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
}

export default Loading;
