import { Toolbar, ToolbarProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";

const StyledToolbar = styled(Toolbar)<ToolbarProps>(({ theme }) => ({
  //position: "absolute",
  //bottom: 0,

  backgroundColor: grey[100],
  // width: "100%",
  height: "4.4em",
  paddingTop: "0.5em",
  paddingBottom: "0.5em",
  paddingRight: "0.5em",
  paddingLeft: "0.5em",
  "& > span": {
    "> .MuiButtonBase-root": {
      height: "100%",
      padding: "0",
    },
    border: "1px solid rgba(158, 158, 158, 0.5)",
    marginLeft: "0",
    marginRight: "0.5em",
    borderRadius: "12px",
    height: "100%",
    backgroundColor: theme.palette.background.default,
  },
  "& .MuiFormControlLabel-root": {
    marginLeft: "0",
    marginRight: "0",
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "8px",
  },
  "& .MuiSvgIcon-root": {
    height: "38px",
  },
}));

function FighterToolbar(props: FighterToolbarProps) {
  return <StyledToolbar disableGutters>{props.children}</StyledToolbar>;
}

interface FighterToolbarProps extends ToolbarProps {
  children: React.ReactElement;
}

export default FighterToolbar;
