import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import VideocamIcon from "@mui/icons-material/Videocam";

import React from "react";

export default function CamSelector(props: CamSelectorProps) {
  const validSelected =
    props.camDevices.find((s) => s.deviceId === props.selectedCam)?.deviceId ||
    "";
  return (
    <React.Fragment>
      <Grid item sx={{ margin: "auto" }} textAlign="center" xs={1}>
        <VideocamIcon fontSize="large" />
      </Grid>
      <Grid item xs={11}>
        <FormControl fullWidth>
          <Select onChange={props.onChange} value={validSelected}>
            {props.camDevices.map((dev) => (
              <MenuItem value={dev.deviceId} key={dev.deviceId}>
                {dev.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}

export interface CamSelectorProps {
  onChange: (change: SelectChangeEvent) => void;
  selectedCam: string;
  camDevices: Array<MediaDeviceInfo>;
}
