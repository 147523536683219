import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Slider,
  ToggleButton,
  Typography,
} from "@mui/material";
import { FightModerationMode } from "@tatami-web/domain";
import {
  createFight,
  CreateFightFormValues,
  CreateFightState,
  formatMinutes,
  useAppDispatch,
} from "@tatami-web/shared";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { Select, TextField, ToggleButtonGroup } from "formik-mui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import i18n, { availableLanguages } from "../../../../i18n";
import ChessFightRulesForm from "./ChessFightRulesForm";
import FreeFightRulesForm from "./FreeFightRulesForm";

const FightCreationSchema = Yup.object().shape({
  title: Yup.string()
    .min(6, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  description: Yup.string().min(2, "Too Short!").max(500, "Too Long!"),
  mode: Yup.string().required("Required"),
});

function CreateFightForm(props: CreateFightFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (
    values: CreateFightFormValues,
    formikHelpers: FormikHelpers<CreateFightFormValues>
  ) => {
    const fight: CreateFightState = await dispatch(createFight(values));
    navigate(
      `/fights/fight/${encodeURIComponent(
        fight.title.replaceAll(" ", "_")
      )}?f=${fight.uid}`
    );
  };

  const langs = availableLanguages;

  const initialValues: CreateFightFormValues = {
    title: "",
    description: "",
    lang: i18n.language,
    mode: FightModerationMode.Chess,
    fightRules: {
      introductionDuration: 300,
      closeDuration: 300,
    },
    freeFightRules: {
      maxDuration: 1200,
    },
    chessFightRules: {
      maxSpeechDuration: 1200,
      interruptions: 0,
      interruptionTime: 60,
    },
  };

  return (
    <Box component="main" sx={{ p: 2 }}>
      <Container component="main" maxWidth="lg">
        <Formik
          initialValues={initialValues}
          validationSchema={FightCreationSchema}
          onSubmit={handleSubmit}
          validateOnBlur
        >
          {({ submitForm, isSubmitting, errors, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {t("fight.create.subject.title")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Field
                      required
                      component={TextField}
                      name="title"
                      type="text"
                      label={t("fight.create.form.title.label")}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Field
                      component={TextField}
                      InputProps={{
                        multiline: true,
                        rows: 6,
                      }}
                      name="description"
                      variant="outlined"
                      type="text"
                      label={t("fight.create.form.description.label")}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth>
                    <Field
                      component={Select}
                      name="lang"
                      label={t("fight.create.form.language.label")}
                      type="text"
                      id="lang"
                    >
                      {langs.map((lang) => (
                        <MenuItem value={lang} key={lang}>
                          {t(`fight.create.form.language.option.${lang}`)}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl required fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="fightRules.introductionDuration"
                    >
                      {t("fight.create.form.introductionDuration.label")}
                    </InputLabel>
                    <Field
                      component={Slider}
                      name="fightRules.introductionDuration"
                      id="fightRules.introductionDuration"
                      onChange={(e: React.SyntheticEvent, v: string) => {
                        setFieldValue("fightRules.introductionDuration", v);
                      }}
                      valueLabelFormat={formatMinutes}
                      valueLabelDisplay="auto"
                      marks={[
                        {
                          value: 60,
                          label: formatMinutes(60),
                        },
                        {
                          value: 300,
                          label: formatMinutes(300),
                        },
                        {
                          value: 600,
                          label: formatMinutes(600),
                        },
                      ]}
                      defaultValue={300}
                      min={60}
                      max={600}
                      step={60}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <FormControl required fullWidth>
                    <InputLabel shrink htmlFor="fightRules.closeDuration">
                      {t("fight.create.form.closeDuration.label")}
                    </InputLabel>
                    <Field
                      component={Slider}
                      name="fightRules.closeDuration"
                      id="fightRules.closeDuration"
                      onChange={(e: React.SyntheticEvent, v: string) => {
                        setFieldValue("fightRules.closeDuration", v);
                      }}
                      as="input"
                      valueLabelFormat={formatMinutes}
                      valueLabelDisplay="auto"
                      marks={[
                        {
                          value: 60,
                          label: formatMinutes(60),
                        },
                        {
                          value: 300,
                          label: formatMinutes(300),
                        },
                        {
                          value: 600,
                          label: formatMinutes(600),
                        },
                      ]}
                      defaultValue={300}
                      min={60}
                      max={600}
                      step={60}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl required fullWidth>
                    <InputLabel
                      shrink
                      htmlFor="mode"
                      sx={{ transform: "translate(14px, -20px) scale(0.75)" }}
                    >
                      {t("fight.create.form.mode.label")}
                    </InputLabel>
                    <Field
                      component={ToggleButtonGroup}
                      name="mode"
                      id="mode"
                      type="checkbox"
                      size="small"
                      exclusive
                      as="input"
                    >
                      <ToggleButton value={FightModerationMode.Free}>
                        {t("fight.create.form.mode.option.free")}
                      </ToggleButton>
                      <ToggleButton value={FightModerationMode.Chess}>
                        {t("fight.create.form.mode.option.chess")}
                      </ToggleButton>
                    </Field>
                  </FormControl>
                </Grid>
                {(() => {
                  switch (values.mode) {
                    case FightModerationMode.Free:
                      return (
                        <FreeFightRulesForm
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      );
                    case FightModerationMode.Chess:
                      return (
                        <ChessFightRulesForm
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {t("fight.create.form.button.submit")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
}

interface CreateFightFormProps {}

export default CreateFightForm;
