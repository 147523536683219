import { Participant, UserSwitchAction } from "@tatami-web/domain";
import React from "react";
import SwitchAction from "./SwitchAction";

function OpponentActions(props: OpponentActionsProps) {
  return (
    <React.Fragment>
      {Object.keys(props.participant.switches).map((userSwitch, i) => {
        const sytheticAction: UserSwitchAction = {
          _type: "",
          kind: "Switch",
          field: userSwitch,
          state: props.participant.switches[userSwitch],
        };
        return (
          <SwitchAction
            key={userSwitch}
            action={sytheticAction}
            participant={props.participant}
            readOnly
          />
        );
      })}
    </React.Fragment>
  );
}
interface OpponentActionsProps {
  participant: Participant;
}

export default OpponentActions;
