/** @jsxImportSource @emotion/react */

import { Box, css, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FightPhase } from "@tatami-web/shared";
import theme from "../../../../../theme";

function TimedPhase({ phase }: TimedPhaseProps) {
  const { t } = useTranslation();
  const [running, setRunning] = useState<Boolean>(false);

  useEffect(() => {
    window.setTimeout(() => {
      setRunning(true);
    }, 100);
  }, []);

  return (
    <Box
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          background-color: ${theme.palette.primary.main};
          height: 3em;
        `}
      >
        <Typography
          variant="h4"
          css={css`
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
          `}
        >
          {t(`fight.room.agenda.phase.name.${phase.phase}`)}
        </Typography>
      </div>
      {phase.duration ? (
        <div
          css={css`
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            background-color: white;
            width: ${running ? 100 : 0}%;
            transition: width ${phase.duration + "s" || "60s"};
            transition-timing-function: linear;
          `}
        />
      ) : null}
    </Box>
  );
}

interface TimedPhaseProps {
  phase: FightPhase;
}

export default TimedPhase;
