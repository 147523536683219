import { FormControl, Grid, InputLabel, Slider } from "@mui/material";
import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { CreateFightFormValues } from "@tatami-web/shared";
import { formatMinutes } from "@tatami-web/shared";

function FreeFightRulesForm(props: FreeFightRulesFormProps) {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={6} lg={3}>
      <FormControl required fullWidth>
        <InputLabel shrink htmlFor="freeFightRules.maxDuration">
          {t("fight.create.form.maxDuration.label")}
        </InputLabel>
        <Field
          component={Slider}
          name="freeFightRules.maxDuration"
          id="freeFightRules.maxDuration"
          onChange={(e: React.SyntheticEvent, v: string) => {
            props.setFieldValue("freeFightRules.maxDuration", v);
          }}
          valueLabelFormat={formatMinutes}
          valueLabelDisplay="on"
          defaultValue={1200}
          min={600}
          max={1800}
          step={60}
        />
      </FormControl>
    </Grid>
  );
}

interface FreeFightRulesFormProps {
  values: CreateFightFormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export default FreeFightRulesForm;
