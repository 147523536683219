import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function NoMediaPermission() {
  const { t } = useTranslation();

  return (
    <Grid item>
      <Typography variant="h3">{t("error.mediaPermission.title")}</Typography>
      <Typography paragraph>
        {t("error.mediaPermission.description")}
      </Typography>
    </Grid>
  );
}

export default NoMediaPermission;
